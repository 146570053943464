@import '../../../styles/customMediaQueries.css';

.root {
    position: relative;
    padding-top: 24px;
    margin-top: 12px;
    padding-bottom: 17px;
    border-bottom: 1px solid var(--colorGrey100);

    @media (--viewportMedium) {
        padding-top: 16px;
        padding-bottom: 0;
        border-bottom: 0;
    }
}

.contentWrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 300px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 0px;

    @media (--viewportMedium) {
        padding-top: 0px;
        padding-bottom: 8px;
        margin-top: 0px;
    }
}

.formWrapper {
    padding-left: 12px;
}

.contentWrapperSidebar {
    margin-top: 12px;
}

.labelPopup {
    composes: h5 from global;

    font-weight: var(--fontWeightSemiBold);
    padding: 11px 0 13px 0;
    margin: 0 18px 0 0;
}

@media (--viewportMedium) {
    .label {
        padding: 8px 0 16px 0;
    }
}

.inputsWrapper {
    display: flex;

    @media (--viewportMedium) {
        padding-bottom: 5px;
    }
}

.minValue,
.maxValue {
    width: 48px;
    text-align: center;

    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    @media (--viewportMedium) {
        padding: 3px 0 3px 0;
    }
}

.valueInSidebar {
    width: 72px;
}

.valueSeparator {
    margin: 6px 8px 0 8px;

    @media (--viewportMedium) {
        margin: 5px 8px 0 8px;
    }
}

.sliderWrapper {
    display: flex;
    padding: 17px 0 25px 0;

    @media (--viewportMedium) {
        padding: 3px 0 13px 0;
    }
}